.enableCounter {
    cursor: pointer;
    color: $primary !important;
}

.disableCounter {
    cursor: pointer;
    color: $secondary !important;
}

.hand {
    cursor: pointer;
    .card:hover {
        box-shadow: 5px 10px 24px 0 rgba(34, 41, 47, .2) !important;
    }
}

.selectedRegistrationStation {
    //filter: drop-shadow(2px 2px 5px $primary)
    box-shadow: 0px 4px 7px -5px $primary;
 }

.headerSelected {
  border: 1px solid $primary !important;
  background: $primary;
  color: white;
}

.scanFab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px !important;
  height: 120px !important;
  // margin-top: 28px;
  border-radius: 50%;
  background: $primary;
  color: white;
  
  i {
    font-size: 3rem;
    color: white !important;
  }
}

.pulse {
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity 0.3s, transform 0.3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    //   z-index: -1;
  }

  overflow: visible;
  position: relative;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.bgEating {
  background: #2385f4 !important
}

.bgAte {
  background: #66BB6A!important
}