.pad {
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: fit-content;
  padding: 0.2em;
}

.rowPad {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #f3f2f7;
}

@mixin keyStyle {
  background-color: transparent;
  outline: 0px;
  border: 0px;
  border-right: 2px solid #f3f2f7;
  color: #717079;
}

.key {
  @include keyStyle;
  width: 2.5em;
  height: 2.5em;
}

.keyLetter {
  @include keyStyle;
  width: 2.3em;
  height: 2.3em;
  font-size: 24px;
}

.keyabc {
  @include keyStyle;
  width: 3.2em;
  height: 3.2em;
  font-size: 22px;
}

.key123 {
  @include keyStyle;
  width: 2.5em;
  height: 2.5em;
  font-size: 22px;
}

.key:last-child {
    border-right: none;
}

.key:active {
    background-color: #d6d6d622;
}

.keyLetter:last-child {
    border-right: none;
}

.keyLetter:active {
    background-color: #d6d6d622;
}

.keyabc:last-child {
    border-right: none;
}

.keyabc:active {
    background-color: #d6d6d622;
}

.key123:last-child {
    border-right: none;
}

.key123:active {
    background-color: #d6d6d622;
}


.LineCursor {
  font-size:28px;
  color: black;
  text-transform:uppercase;
}

.blink {
  animation-name: blink;
  animation-duration: 0.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:blink;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes blink{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 0.0; }
}

@-webkit-keyframes blink {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes blink {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 0.0; }
}

.numpad-text {
    height: 30px;
    max-width: 80%;
    min-width: 40%;
    margin-bottom: 15px;
    padding: 0px 5px;
    overflow: hidden;
    font-size: 28px;
    color: #2385f4;
    letter-spacing: 4px;
}